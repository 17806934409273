<ion-header no-border>
  <ion-toolbar>
    @if (showSkip) {
      <ion-buttons slot="end">
        <ion-button color="primary" (click)="start()">
          {{ 'button.skip' | translate}}
        </ion-button>
      </ion-buttons>
    }
  </ion-toolbar>
</ion-header>

<ion-content>
  <swiper-container #ad6WelcomeSwiper>
    <swiper-slide>
      <ad6-about-segment segment="welcome" image="ad6-banner.png" />

      <ion-button fill="solid" color="ad6-blue2" (click)="next()">
        {{ 'button.next' | translate }}
        <ion-icon name="arrow-forward" slot="end" />
      </ion-button>
    </swiper-slide>

    <swiper-slide>
      <ad6-about-segment segment="radiotv" image="carrousel2.jpg" />
      <ad6-about-segment segment="pushmessages" />
      <ion-button fill="solid" color="ad6-blue2" (click)="next()">
        {{ 'button.next' | translate }}
        <ion-icon name="arrow-forward" slot="end" />
      </ion-button>
    </swiper-slide>

    <swiper-slide>
      <ad6-about-segment segment="photos" image="carrousel3.jpg" />
      <ad6-about-segment segment="privacy" [link]="privacyLink" />
      <ion-button fill="solid" color="ad6-blue2" (click)="next()">
        {{ 'button.next' | translate }}
        <ion-icon name="arrow-forward" slot="end" />
      </ion-button>
    </swiper-slide>

    <swiper-slide>
      <ad6-about-segment segment="creators" />

      <div class="ion-justify-content-center flusso-logo">
        <img src="assets/images/flusso-logo.png" alt="Flusso logo" (click)="gotoFlusso()"/>
      </div>

      <ad6-card-flusso class="ion-margin-bottom" />
      <ion-button fill="solid" color="ad6-blue2" (click)="next()">
        {{ 'button.next' | translate }}
        <ion-icon name="arrow-forward" slot="end" />
      </ion-button>
    </swiper-slide>

    <swiper-slide>
      <img src="assets/images/carrousel4.jpg" alt=""/>
      <p>{{ 'about.title.havefun' | translate }}</p>
      <ion-button fill="solid" color="ad6-blue2" (click)="start()">
        {{ 'button.start' | translate }}
        <ion-icon name="arrow-forward" slot="end" />
      </ion-button>
    </swiper-slide>
  </swiper-container>
</ion-content>
