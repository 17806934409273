import {
  Component, CUSTOM_ELEMENTS_SCHEMA, effect, ElementRef, inject, viewChild,
} from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Ad6AboutSegmentComponent } from '@components/ad6-about-segment/ad6-about-segment.component';
import { Ad6CardFlussoComponent } from '@components/ad6-card-flusso/ad6-card-flusso.component';
import { Constants } from '@constant/constants';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
  ModalController,
} from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { AppDataService } from '@services/app-data.service';
import Swiper from 'swiper';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'ad6-page-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
  imports: [
    Ad6AboutSegmentComponent,
    Ad6CardFlussoComponent,
    TranslateModule,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonIcon,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WelcomePage {
  private readonly appDataService = inject(AppDataService);
  private readonly modalController = inject(ModalController);

  ad6WelcomeSwiper = viewChild<ElementRef<SwiperContainer>>('ad6WelcomeSwiper');
  ad6WelcomeSwiperEffect = effect(() => {
    if (this.ad6WelcomeSwiper()) {
      this.swiper = this.ad6WelcomeSwiper()!.nativeElement.swiper;

      this.swiper.on('slideChangeTransitionStart', () => {
        this.onSlideChange();
      });
    }
  });

  content = viewChild(IonContent);
  privacyLink = Constants.urlPrivacy;
  showSkip = true;
  swiper: Swiper | null = null;

  gotoFlusso(): void {
    Browser.open({ url: Constants.flussoWebsite });
  }

  next(): void {
    this.swiper!.slideNext(1000);
    this.onSlideChange();
  }

  onSlideChange(): void {
    this.showSkip = !this.swiper!.isEnd;
    this.content()!.scrollToTop(500);
  }

  start(): void {
    this.appDataService.toggleSkipWelcomePage();
    this.modalController.dismiss({ data: 'letsStartApp' });
  }
}
